import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'

import Auth, { Group } from '../../components/Auth'

import Loading from '../../components/Loading'

import useAuth from '../../util/useAuth'

const RequestPassword: React.FC = () => {
  const { getUser } = useAuth()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUser()
      .then(() => navigate('/'))
      .catch(() => setLoading(false))
  }, [])

  if (loading) return <Loading isAuth />
  return (
    <Auth title='Email Sent' subTitle='It can take a few minutes to receive your password recovery link.'>
      <p>If you do not receive this link, please contact your administrator.</p>
      <Group>
        <Link to='/auth/login'>Back to Log In</Link>
      </Group>
    </Auth>
  )
}
export default RequestPassword
