import React from 'react'
import styled from 'styled-components'

import Spinner from '@paljs/ui/Spinner'
import Layout from '../../layout'

const LoadingDiv = styled.div`
  position: relative;
  height: 100%;
  div:first-child {
    background-color: ${({ theme }) => theme.layoutBackgroundColor} !important;
  }
`
function index({ isAuth = false }) {
  const LoadingBox = isAuth ? React.Fragment : LoadingDiv
  return (
    <Layout isAuth={isAuth}>
      <LoadingBox>
        <Spinner status='Info' size='Giant' />
      </LoadingBox>
    </Layout>
  )
}

export default index
