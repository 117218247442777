import React from 'react'

import { CardBody } from '@paljs/ui/Card'

import { AuthStyle, Group, CardAuth, ImageContainer } from './styled'
import Layout from '../../layout'

interface AuthProps {
  title?: string
  subTitle?: string
}
const Auth: React.FC<AuthProps> = ({ subTitle, title, children }) => {
  return (
    <Layout isAuth seoTitle={title}>
      <CardAuth>
        <CardBody>
          <AuthStyle subTitle={subTitle}>
            <ImageContainer>
              <img src='/icons/icon-144x144.png' />
            </ImageContainer>
            {title && <h1>{title}</h1>}
            {subTitle && <p>{subTitle}</p>}
            {children}
          </AuthStyle>
        </CardBody>
      </CardAuth>
    </Layout>
  )
}

export { Group }
export default Auth
